import React, { useContext } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import Layout from 'containers/Layout/Layout';
import Loader from 'components/Loader/Loader';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  LISTING_POSTS_PAGE_SEARCH,
  SINGLE_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_PROFILE_FAVORITE,
  AGENT_PROFILE_CONTACT,
  AGENT_PROFILE_REVIEWS,
  PRICING_PLAN_PAGE,
  PRIVACY_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_IMAGE_EDIT_PAGE,
  AGENT_PASSWORD_CHANGE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  Verification_Email_PAGE,
  Reset_Password_PAGE,
  Merg_Account_PAGE,
  PAYMENT_PAGE,
  NOTIFICATION_PAGE,
  RESERVATION_PAGE,
  CONTACT_PAGE,
  AGENT_PROFILE_RECOMMENDED,
  RENTAL_CAR,
  FLIGHTS_PAGE,
  FLIGHTS_SEARCH_PAGE,
  RENTAL_CAR_SEARCH_PAGE,
  RENTAL_CAR_PAGE,
  COMPANIES_PAGE,
  COMPANY_PROFILE_PAGE,
  COMPANY_PROFILE_CARS_PAGE,
  COMPANY_PROFILE_TOURS_PAGE,
  COMPANY_PROFILE_REVIEWS_PAGE,
  ATTRACTIONS_PAGE,
  ATTRACTIONS_SEARCH_PAGE,
  ATTRACTION_DETAILS_PAGE,
  ATTRACTION_BOOK,
} from './settings/constant';

// protected route
function RequireAuth({ children }) {
  let { loggedIn } = useContext(AuthContext);
  let location = useLocation();
  if (!loggedIn) {
    return <Navigate to={LOGIN_PAGE} state={{ from: location }} />;
  }

  return children;
}

// public routes
const HomePage = React.lazy(() => import('containers/Home/Home'));
const FlightsPage = React.lazy(() => import('containers/Flights/Flights'));
const FlightsPageSearch = React.lazy(() =>
  import('containers/Flights/List/FlightsList')
);
const AttractionsPage = React.lazy(() =>
  import('containers/Attractions/Attractions')
);
const AttractionsPageSearch = React.lazy(() =>
  import('containers/Attractions/List/attractionList')
);
const AttractionDetailsPage = React.lazy(() =>
  import('containers/Attractions/Details/AttractionDetails')
);
const BookingAttraction = React.lazy(() =>
  import('containers/Attractions/ReservationPage/ReservationPage')
);
const ContactPage = React.lazy(() => import('containers/Contact/AgentContact'));
const ListingPage = React.lazy(() => import('containers/Listing/Listing'));
// Companies
const CompaniesPage = React.lazy(() =>
  import('containers/Companies/Companies')
);
const ComapnyDetailsViewPage = React.lazy(() =>
  import('containers/Companies/CompanyDetails/CompanyDetailsView')
);
const ComapnyAboutViewPage = React.lazy(() =>
  import('containers/Companies/CompanyDetails/CompanyProfile')
);
const ComapnyToursViewPage = React.lazy(() =>
  import('containers/Companies/CompanyDetails/CompanyItemLists')
);
const ComapnyCarsViewPage = React.lazy(() =>
  import('containers/Companies/CompanyDetails/CompanyRentalCar')
);
const ComapnyReviewsViewPage = React.lazy(() =>
  import('containers/Companies/CompanyDetails/CompanyReviews')
);

///////////////////////////////////////////////////////
// Rental Car
const RentalCarPage = React.lazy(() =>
  import('containers/RentalCar/RentalCar')
);
const RentalCarSearchPage = React.lazy(() =>
  import('containers/RentalCar/RentalCarResult/RentalCarResult')
);
const RentalCarViewPage = React.lazy(() =>
  import('containers/RentalCar/SingleCarPage/SingleCarPage')
);
/////////////////////////////////////////////////////////

const ListingPageSearch = React.lazy(() =>
  import('containers/Listing/ListingSearch')
);
const SinglePageView = React.lazy(() =>
  import('containers/SinglePage/SinglePageView')
);
const AgentDetailsViewPage = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentDetailsViewPage')
);
const AgentItemLists = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentItemLists')
);
const AgentFavItemLists = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentFavItemLists')
);
const AgentContact = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentContact')
);
const AgentReviews = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentReviews')
);
const AgentRecommended = React.lazy(() =>
  import('containers/Agent/AccountDetails/AgentRecommendedTours')
);
const PricingPage = React.lazy(() => import('containers/Pricing/Pricing'));
const PrivacyPage = React.lazy(() => import('containers/Privacy/Privacy'));
const SignInPage = React.lazy(() => import('containers/Auth/SignIn/SignIn'));
const SignUpPage = React.lazy(() => import('containers/Auth/SignUp/SignUp'));
const VerificationPage = React.lazy(() =>
  import('containers/Auth/verification/Verification')
);
const ResetPasswordPage = React.lazy(() =>
  import('containers/Auth/resetPassword/ResetPassword')
);
const MergAccountPage = React.lazy(() =>
  import('containers/Auth/mergAccount/MergAccount')
);
const ForgetPasswordPage = React.lazy(() =>
  import('containers/Auth/ForgetPassword')
);
const NotFound = React.lazy(() => import('containers/404/404'));
const NotificationPage = React.lazy(() =>
  import('containers/Notification/notificationPage')
);

// protected route
const PaymentPage = React.lazy(() => import('containers/Payment/PaymentPage'));

const ReservationPage = React.lazy(() =>
  import('containers/ReservationPage/ReservationPage')
);
const AddListingPage = React.lazy(() =>
  import('containers/AddListing/AddListing')
);
const AgentAccountSettingsPage = React.lazy(() =>
  import('containers/Agent/AccountSettings/AgentAccountSettingsPage')
);
const AgentCreateOrUpdateForm = React.lazy(() =>
  import('containers/Agent/AccountSettings/AgentCreateOrUpdateForm')
);
const AgentPictureChangeForm = React.lazy(() =>
  import('containers/Agent/AccountSettings/AgentPictureChangeForm')
);
const ChangePassWord = React.lazy(() =>
  import('containers/Agent/AccountSettings/ChangePassWordForm')
);

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={HOME_PAGE} element={<Layout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path={FLIGHTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <FlightsPage />
            </React.Suspense>
          }
        />
        <Route
          path={FLIGHTS_SEARCH_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <FlightsPageSearch />
            </React.Suspense>
          }
        />
        <Route
          path={ATTRACTIONS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AttractionsPage />
            </React.Suspense>
          }
        />
        <Route
          path={ATTRACTIONS_SEARCH_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AttractionsPageSearch />
            </React.Suspense>
          }
        />
        <Route
          path={`${ATTRACTION_DETAILS_PAGE}/:id`}
          element={
            <React.Suspense fallback={<Loader />}>
              <AttractionDetailsPage />
            </React.Suspense>
          }
        />
        <Route
          path={CONTACT_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ContactPage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE_SEARCH}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPageSearch />
            </React.Suspense>
          }
        />
        <Route
          path={`${SINGLE_POST_PAGE}/:tourId`}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView />
            </React.Suspense>
          }
        />
        <Route
          path={NOTIFICATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <NotificationPage />
            </React.Suspense>
          }
        />
        <Route
          path={RENTAL_CAR}
          element={
            <React.Suspense fallback={<Loader />}>
              <RentalCarPage />
            </React.Suspense>
          }
        />
        <Route
          path={RENTAL_CAR_SEARCH_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RentalCarSearchPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${RENTAL_CAR_PAGE}/:carId`}
          element={
            <React.Suspense fallback={<Loader />}>
              <RentalCarViewPage />
            </React.Suspense>
          }
        />
        <Route
          path={COMPANIES_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <CompaniesPage />
            </React.Suspense>
          }
        />
        {/* Nested routes for Companies page */}
        <Route
          path={`${COMPANY_PROFILE_PAGE}/:companyId`}
          element={
            <React.Suspense fallback={<Loader />}>
              <ComapnyDetailsViewPage />
            </React.Suspense>
          }
        >
          <Route
            path={`${COMPANY_PROFILE_PAGE}/:companyId`}
            element={
              <React.Suspense fallback={<Loader />}>
                <ComapnyAboutViewPage />
              </React.Suspense>
            }
          />
          <Route
            path={COMPANY_PROFILE_TOURS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ComapnyToursViewPage />
              </React.Suspense>
            }
          />
          <Route
            path={COMPANY_PROFILE_CARS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ComapnyCarsViewPage />
              </React.Suspense>
            }
          />
          <Route
            path={COMPANY_PROFILE_REVIEWS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ComapnyReviewsViewPage />
              </React.Suspense>
            }
          />
        </Route>
        {/* end of Nested routes */}
        {/* Nested routes for agent page */}
        <Route
          path={AGENT_PROFILE_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <AgentDetailsViewPage />
            </React.Suspense>
          }
        >
          <Route
            path={AGENT_PROFILE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentItemLists />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_FAVORITE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentFavItemLists />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_CONTACT}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentContact />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_REVIEWS}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentReviews />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PROFILE_RECOMMENDED}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentRecommended />
              </React.Suspense>
            }
          />
        </Route>
        {/* end of Nested routes */}
        <Route
          path={PRICING_PLAN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PricingPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${RESERVATION_PAGE}/:tourId`}
          element={
            <React.Suspense fallback={<Loader />}>
              <ReservationPage />
            </React.Suspense>
          }
        />
        <Route
          path={`${ATTRACTION_BOOK}/:attractionId`}
          element={
            <React.Suspense fallback={<Loader />}>
              <BookingAttraction />
            </React.Suspense>
          }
        />
        <Route
          path={PRIVACY_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPage />
            </React.Suspense>
          }
        />
        <Route
          path={LOGIN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignInPage />
            </React.Suspense>
          }
        />
        <Route
          path={REGISTRATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignUpPage />
            </React.Suspense>
          }
        />
        <Route
          path={Verification_Email_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <VerificationPage />
            </React.Suspense>
          }
        />
        <Route
          path={Reset_Password_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ResetPasswordPage />
            </React.Suspense>
          }
        />
        <Route
          path={Merg_Account_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <MergAccountPage />
            </React.Suspense>
          }
        />
        <Route
          path={FORGET_PASSWORD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgetPasswordPage />
            </React.Suspense>
          }
        />
        {/* Protected routes */}
        {/* <Route
          path={ADD_HOTEL_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AddListingPage />
              </RequireAuth>
            </React.Suspense>
          }
        /> */}
        <Route
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <RequireAuth>
                <AgentAccountSettingsPage />
              </RequireAuth>
            </React.Suspense>
          }
        >
          <Route
            path={AGENT_ACCOUNT_SETTINGS_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentCreateOrUpdateForm />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_IMAGE_EDIT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentPictureChangeForm />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PASSWORD_CHANGE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ChangePassWord />
              </React.Suspense>
            }
          />
          <Route
            path={PAYMENT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <PaymentPage />
              </React.Suspense>
            }
          />
        </Route>

        {/* end of Protected routes*/}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
