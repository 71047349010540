import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  const signIn = (params, redirectUrl = '/profile') => {
    setLoggedIn(true);
    navigate('/profile', { replace: true });
  };

  const signUp = (params) => {
    // console.log(params);
    navigate('/verification-email', { replace: true, state: { params } });
  };

  const logOut = () => {
    const rememberMe = localStorage.getItem('rememberMe');

    // if (rememberMe === false) {
    localStorage.removeItem('token');
    // }
    // console.log('rememberMe logout : ' + rememberMe);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
