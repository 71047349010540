import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  FLIGHTS_PAGE,
  CONTACT_PAGE,
  PRICING_PLAN_PAGE,
  RENTAL_CAR,
  ATTRACTIONS_PAGE,
  COMPANIES_PAGE,
} from 'settings/constant';
import './headerStyle.css';
const { SubMenu } = Menu;

const menuItems = [
  {
    label: <NavLink to={HOME_PAGE}>Home</NavLink>,
    key: 'menu-1',
  },
  {
    label: <NavLink to={LISTING_POSTS_PAGE}>Tours</NavLink>,
    key: 'menu-2',
  },
  {
    label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
    key: 'menu-3',
  },
  // {
  //   label: <NavLink to={PRICING_PLAN_PAGE}>Pricing</NavLink>,
  //   key: 'menu-4',
  // },
  {
    label: (
      <SubMenu title="More" key="submenu-more">
        <Menu.Item key="submenu-more-RentalCar">
          <NavLink to={FLIGHTS_PAGE}>Flights</NavLink>
        </Menu.Item>
        <Menu.Item key="submenu-more-RentalCar">
          <NavLink to={RENTAL_CAR}>Rental Car</NavLink>
        </Menu.Item>
        <Menu.Item key="submenu-more-companies">
          <NavLink to={COMPANIES_PAGE}>Companies</NavLink>
        </Menu.Item>
        <Menu.Item key="submenu-more-companies">
          <NavLink to={ATTRACTIONS_PAGE}>Attractions</NavLink>
        </Menu.Item>
      </SubMenu>
    ),
    key: 'menu-6',
  },
];

const MainMenu = ({ className }) => {
  return <Menu className={className} items={menuItems} />;
};

export default MainMenu;
