import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Radio } from 'antd';
import ViewWithPopup from 'components/UI/ViewWithPopup/ViewWithPopup';
import InputIncDec from 'components/UI/InputIncDec/InputIncDec';
import { getStateFromUrl } from '../url-handler';
import { LISTING_POSTS_PAGE_SEARCH } from 'settings/constant';
import { GetCategories } from 'services/getData/GetCategories';
import { GetCities } from 'services/getData/GetCities';
import { search } from 'services/search/Search';
import CategorySearchWrapper, {
  ProgramDaysWrapper,
  ItemWrapper,
  ActionWrapper,
} from './CategorySearch.style';
import './CategorySearch.style';

const CategorySearch = ({ updateFilters, location }) => {
  const navigate = useNavigate();
  const searchParams = getStateFromUrl(location);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const initialSearchState = {
    category: searchParams.category || [],
    city: searchParams.city || [],
    programDays: parseInt(searchParams.programDays) || 0,
    compareNumOfDays: searchParams.compareNumOfDays || 'equals',
  };
  const [searchState, setSearchState] = useState(initialSearchState);
  const [countDays, setProgramDays] = useState(searchState.programDays);
  const [comparison, setComparison] = useState(searchState.compareNumOfDays);

  useEffect(() => {
    const fetchData = async () => {
      const catResponse = await GetCategories();
      const cityResponse = await GetCities();

      setCategories(
        catResponse.categoriesRecords.map((item) => ({
          label: item.name_en,
          value: item.id,
        }))
      );
      setCities(
        cityResponse.citiesRecords.map((item) => ({
          label: item.name_en,
          value: item.id,
        }))
      );
    };

    fetchData();
  }, []);

  const onChange = (value, type) => {
    const updatedState = { ...searchState, [type]: value };
    setSearchState(updatedState);
  };

  const handleProgramDaysApply = () => {
    setSearchState((prevState) => ({
      ...prevState,
      programDays: countDays,
      compareNumOfDays: comparison,
    }));
  };

  const onSearchReset = () => {
    setSearchState({ ...initialSearchState, category: [], city: [] });
    setProgramDays(0);
    setComparison('equals');
  };

  const applyFilters = async () => {
    const requestData = {
      toursRecord: {
        ...searchState,
        numOfDays: countDays,
        compareNumOfDays: comparison,
      },
    };

    try {
      const response = await search(requestData);
      navigate(LISTING_POSTS_PAGE_SEARCH, {
        state: {
          searchData: response.data.toursRecords,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <CategorySearchWrapper>
      <ViewWithPopup
        className={searchState.category.length ? 'activated' : ''}
        key="categorySearch"
        noView={true}
        view={
          <Button type="default">{`Category: ${
            searchState.category.length > 0
              ? searchState.category
                  .map(
                    (id) => categories.find((cat) => cat.value === id)?.label
                  )
                  .join(', ')
              : 'Select Category'
          }`}</Button>
        }
        popup={
          <Checkbox.Group
            options={categories}
            defaultValue={searchState.category}
            onChange={(value) => onChange(value, 'category')}
          />
        }
      />

      <ViewWithPopup
        className={searchState.city.length ? 'activated' : ''}
        key="citySearch"
        noView={true}
        view={
          <Button type="default">{`City: ${
            searchState.city.length > 0
              ? searchState.city
                  .map((id) => cities.find((city) => city.value === id)?.label)
                  .join(', ')
              : 'Select City'
          }`}</Button>
        }
        popup={
          <Checkbox.Group
            options={cities}
            defaultValue={searchState.city}
            onChange={(value) => onChange(value, 'city')}
          />
        }
      />

      {/* Days and Comparison Selector */}
      <ViewWithPopup
        className={countDays ? 'activated' : ''}
        key="daysSearch"
        noView={true}
        view={
          <Button type="default">{`Number of Days: ${
            countDays > 0 ? countDays : 'Select Days'
          }`}</Button>
        }
        popup={
          <ProgramDaysWrapper>
            <ItemWrapper>
              <strong>Number of Days</strong>
              <InputIncDec
                id="programDays"
                increment={() => setProgramDays((count) => count + 1)}
                decrement={() =>
                  setProgramDays((count) => Math.max(count - 1, 0))
                }
                value={countDays}
              />
            </ItemWrapper>
            <ItemWrapper>
              {/* <strong>Comparison</strong> */}
              <Radio.Group
                defaultValue={comparison}
                onChange={(e) => setComparison(e.target.value)}
                className="custom-radio-group"
              >
                <Radio value="less">Less than</Radio>
                <Radio value="greater">Greater than</Radio>
                <Radio value="equals">Equals</Radio>
              </Radio.Group>
            </ItemWrapper>
            <ActionWrapper>
              {countDays ? (
                <Button type="default" onClick={() => setProgramDays(0)}>
                  Clear
                </Button>
              ) : (
                ''
              )}
              <Button type="primary" onClick={handleProgramDaysApply}>
                Apply
              </Button>
            </ActionWrapper>
          </ProgramDaysWrapper>
        }
      />
      <div className="view_with__popup">
        <div className="popup_handler">
          <Button type="default" onClick={onSearchReset}>
            Reset
          </Button>
        </div>
      </div>
      <div className="view_with__popup">
        <div className="popup_handler">
          <Button type="default" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
    </CategorySearchWrapper>
  );
};

export default CategorySearch;
