import api from '../API/apiConfig';

export const search = async (data) => {
  try {
    const response = await api.post('/tours/Listtours', data);
    return response;
  } catch (error) {
    return error;
  }
};
