import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  FLIGHTS_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  NOTIFICATION_PAGE,
  RENTAL_CAR,
  ATTRACTIONS_PAGE,
  COMPANIES_PAGE,
} from 'settings/constant';

const MobileMenu = ({ className }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);

  const navigations = [
    {
      label: <NavLink to={HOME_PAGE}>Home</NavLink>,
      key: 'home',
    },
    {
      label: <NavLink to={`${LISTING_POSTS_PAGE}`}>Tours</NavLink>,
      key: 'tours',
    },
    {
      label: <NavLink to={`${FLIGHTS_PAGE}`}>Flights</NavLink>,
      key: 'flights',
    },
    // {
    //   label: <NavLink to={`${PRICING_PLAN_PAGE}`}>Pricing</NavLink>,
    //   key: 'pricing',
    // },
    {
      label: <NavLink to={`${RENTAL_CAR}`}>Rental Car</NavLink>,
      key: 'rental_car',
    },
    {
      label: <NavLink to={`${ATTRACTIONS_PAGE}`}>Attractions</NavLink>,
      key: 'attractions',
    },
    {
      label: <NavLink to={`${COMPANIES_PAGE}`}>Companies</NavLink>,
      key: 'companies',
    },
    {
      label: loggedIn && (
        <NavLink to={`${AGENT_ACCOUNT_SETTINGS_PAGE}`}>
          Account Settings
        </NavLink>
      ),
      key: 'account_settings',
    },
    {
      label: loggedIn && (
        <NavLink to={`${NOTIFICATION_PAGE}`}>Notifications</NavLink>
      ),
      key: 'notifications',
    },
    {
      label: loggedIn && <button onClick={logOut}>Log Out</button>,
      key: 'logout',
    },
  ];

  return <Menu className={className} items={navigations} />;
};

export default MobileMenu;
