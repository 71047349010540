import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
// import { getAnalytics } from "firebase/analytics";
import { getDeviceId } from 'utils/deviceID';

const firebase = {
  apiKey: 'AIzaSyBjjBmZdKXPTtrwAtWQHPGmYxORXX3xgEI',
  authDomain: 'tourism-quanvex.firebaseapp.com',
  projectId: 'tourism-quanvex',
  storageBucket: 'tourism-quanvex.appspot.com',
  messagingSenderId: '638993421490',
  appId: '1:638993421490:web:b24f5a71ae5e2b6c34e5ab',
  measurementId: 'G-WLR8TEWN3Q',
};

const app = initializeApp(firebase);
export const messaging = getMessaging(app);
// const analytics = getAnalytics(app);

export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BLuRIZLhjJ8J1IEeVRVLcamrz0k6voXoQlv1K2RiiHP9uoiH3oDWYjY52YmEHYWCGLTKT4Q6mGFijzC9uVHjRUo',
        // serviceWorkerRegistration: registration,
      });
      const deviceID = await getDeviceId();
      console.log('FCM Token: ', token);
      console.log('device Id: ', deviceID);
      return { token, deviceID };
    } else {
      throw new Error('Notification permission not granted');
    }
  } catch (error) {
    console.error('Permission denied or error occurred', error);
    throw error;
  }
};
// export { messaging };
// export { analytics };
