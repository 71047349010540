import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// install before use:
// import langDetector from "i18next-browser-languagedetector";
// import backend from 'i18next-http-backend'; // install it to use translation files from server

import en from './locales/en.json';
import ar from './locales/ar.json';

const resources = {
  en: { translation: en },
  ar: { translation: ar },
};

i18n
  // .use(langDetector)
  .use(initReactI18next)
  // .use(backend)
  .init({
    debug: true,
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // I'm not using the loading suspense thing right now.
    react: {
      useSuspense: false,
    },
  });

export default i18n;
