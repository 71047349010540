import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import MapAutoComplete from 'components/Map/MapAutoComplete';
import { setStateToUrl, getStateFromUrl } from 'library/helpers/url_handler';
import { mapDataHelper } from 'components/Map/mapDataHelper';
import { LISTING_POSTS_PAGE } from 'settings/constant';
import { NavbarSearchWrapper } from './Header.style';

export default function NavbarSearch() {
  return (
    <NavbarSearchWrapper className="navbar_search">
      <MapAutoComplete />
      <FiSearch />
    </NavbarSearchWrapper>
  );
}
