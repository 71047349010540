import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import 'antd/dist/reset.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { requestPermission, messaging } from './config/firebase';
import { onMessage } from 'firebase/messaging';
import toast, { Toaster } from 'react-hot-toast';

const App = () => {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    localStorage.setItem('lastUrl', window.location.pathname);
    i18n.changeLanguage(navigator.language);
  }, []);

  useEffect(() => {
    requestPermission();
    onMessage(messaging, (payload) => {
      console.log(payload);
      toast(`${payload.notification.title}\n${payload.notification.body}`, {
        duration: 6000,
        icon: '🔔',
      });
    });
  }, []);
  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/firebase-messaging-sw.js')
  //       .then((registration) => {
  //         console.log(
  //           'Service Worker registered with scope:',
  //           registration.scope
  //         );
  //         const requestPermission = async () => {
  //           try {
  //             await Notification.requestPermission();
  //             const token = await getToken(messaging, {
  //               vapidKey:
  //                 'BLuRIZLhjJ8J1IEeVRVLcamrz0k6voXoQlv1K2RiiHP9uoiH3oDWYjY52YmEHYWCGLTKT4Q6mGFijzC9uVHjRUo',
  //               serviceWorkerRegistration: registration,
  //             });
  //             console.log('FCM Token:', token);
  //             // Save the token to your server if needed
  //           } catch (error) {
  //             console.error('Permission denied or error occurred', error);
  //           }
  //         };

  //         requestPermission();
  //       }).catch((err) => {
  //         console.error('Service Worker registration failed:', err);
  //       });
  //   }

  //   // Handle incoming messages
  //   messaging.onMessage((payload) => {
  //     console.log('Message received. ', payload);
  //     // Customize notification here
  //     const notificationTitle = payload.notification.title;
  //     const notificationOptions = {
  //       body: payload.notification.body,
  //     };
  //     new Notification(notificationTitle, notificationOptions);
  //   });
  // }, []);

  // const tawkMessengerRef = useRef();

  // const handleMinimize = () => {
  //   tawkMessengerRef.current.minimize();
  // };

  const onLoad = () => {
    console.log('onLoad works!');
  };

  return (
    <>
      <ToastContainer style={{ marginTop: '60px' }} />
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyles />
          <BrowserRouter>
            <AuthProvider>
              <AppRoutes />
              <TawkMessengerReact
                propertyId="660270f4a0c6737bd124b950"
                widgetId="1hpsoifeh"
                onLoad={onLoad}
              />
              <Toaster position="bottom-left" reverseOrder={false} />
            </AuthProvider>
          </BrowserRouter>
        </React.Fragment>
      </ThemeProvider>
    </>
  );
};
const root = createRoot(document.getElementById('root'));
root.render(<App />);
