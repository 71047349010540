import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Drawer, Radio } from 'antd';
import Heading from 'components/UI/Heading/Heading';
import InputIncDec from 'components/UI/InputIncDec/InputIncDec';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { LISTING_POSTS_PAGE_SEARCH } from 'settings/constant';
import { GetCategories } from 'services/getData/GetCategories';
import { GetCities } from 'services/getData/GetCities';
import { search } from 'services/search/Search';
import { getStateFromUrl } from './url-handler';
import {
  FilterArea,
  FilterElementsWrapper,
  ButtonGroup,
  RoomGuestWrapper,
  ItemWrapper,
} from './MobileSearchView.style';
import './searchStyle.css';

const FilterDrawer = ({ location }) => {
  const navigate = useNavigate();
  const searchParams = getStateFromUrl(location);
  const [toggle, setToggle] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [category, setCategory] = useState(searchParams.category || []);
  const [city, setCity] = useState(searchParams.city || []);
  const [numberOfDays, setNumberOfDays] = useState(
    parseInt(searchParams.numberOfDays) || 0
  );
  const [comparisonType, setComparisonType] = useState(
    searchParams.comparisonType || 'equals'
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await GetCategories();
      setCategories(
        response.categoriesRecords?.map((item) => ({
          label: item.name_en,
          value: item.id,
        }))
      );
    };

    const fetchCities = async () => {
      const response = await GetCities();
      setCities(
        response.citiesRecords?.map((item) => ({
          label: item.name_en,
          value: item.id,
        }))
      );
    };

    fetchCategories();
    fetchCities();
  }, []);

  const handleToggleDrawer = () => setToggle(!toggle);

  const handleApplyFilter = async () => {
    const requestData = {
      toursRecord: {
        city_id: city[0],
        category_id: category[0],
        numOfDays: numberOfDays,
        compareNumOfDays: comparisonType,
      },
    };

    try {
      const response = await search(requestData);
      navigate(LISTING_POSTS_PAGE_SEARCH, {
        state: { searchData: response.data.toursRecords },
      });
    } catch (error) {}
    setToggle(false);
  };

  const handleCloseDrawer = () => setToggle(false);

  return (
    <FilterArea>
      <Button className={toggle ? 'active' : ''} onClick={handleToggleDrawer}>
        More filters
      </Button>
      <Drawer
        className="filter_drawer"
        placement="bottom"
        closable={false}
        maskClosable={false}
        onClose={handleCloseDrawer}
        open={toggle}
        height="100vh"
        maskStyle={{ backgroundColor: 'transparent' }}
      >
        <FilterElementsWrapper>
          <Accordion allowZeroExpanded={true}>
            {/* Category Filter */}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Heading as="h4" content="Category" />
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Checkbox.Group
                  options={categories}
                  value={category}
                  onChange={setCategory}
                />
              </AccordionItemPanel>
            </AccordionItem>
            {/* City Filter */}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Heading as="h4" content="City" />
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Checkbox.Group
                  options={cities}
                  value={city}
                  onChange={setCity}
                />
              </AccordionItemPanel>
            </AccordionItem>
            {/* Number of Days Filter */}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Heading as="h4" content="Number of Days" />
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                    <strong>Number of Days</strong>
                    <InputIncDec
                      id="numberOfDays"
                      increment={() => setNumberOfDays((prev) => prev + 1)}
                      decrement={() =>
                        setNumberOfDays((prev) => Math.max(prev - 1, 0))
                      }
                      value={numberOfDays}
                    />
                  </ItemWrapper>
                  <ItemWrapper>
                    {/* <strong>Comparison</strong> */}
                    <Radio.Group
                      onChange={(e) => setComparisonType(e.target.value)}
                      value={comparisonType}
                      className="radio-inline"
                    >
                      <Radio value="less">Less than</Radio>
                      <Radio value="greater">Greater than</Radio>
                      <Radio value="equals">Equals</Radio>
                    </Radio.Group>
                  </ItemWrapper>
                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <ButtonGroup>
            <Button onClick={handleCloseDrawer}>Cancel Filter</Button>
            <Button type="primary" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </ButtonGroup>
        </FilterElementsWrapper>
      </Drawer>
    </FilterArea>
  );
};

export default FilterDrawer;
