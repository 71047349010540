// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const LISTING_POSTS_PAGE_SEARCH = '/listing/search';
export const SINGLE_POST_PAGE = '/post';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_PROFILE_FAVORITE = 'favorite-post';
export const AGENT_PROFILE_CONTACT = 'contact';
export const AGENT_PROFILE_REVIEWS = 'reviews';
export const AGENT_PROFILE_RECOMMENDED = 'recommended';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = 'edit';
export const AGENT_IMAGE_EDIT_PAGE = 'change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = 'change-password';
export const AGENT_PROFILE_DELETE = '/delete';

// Payment Page
export const PAYMENT_PAGE = 'payment';

// Company
export const COMPANIES_PAGE = '/companies';
export const COMPANY_PROFILE_PAGE = '/company-profile';
export const COMPANY_PROFILE_TOURS_PAGE = 'company-tours';
export const COMPANY_PROFILE_CARS_PAGE = 'company-cars';
export const COMPANY_PROFILE_REVIEWS_PAGE = 'company-reviews';

// Other Pages
export const CONTACT_PAGE = '/contact';
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Rental Car
export const RENTAL_CAR = '/rental-car';
export const RENTAL_CAR_PAGE = '/rental-car-view';
export const RENTAL_CAR_SEARCH_PAGE = '/rental-car-search';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
export const Verification_Email_PAGE = '/verification-email';
export const Reset_Password_PAGE = '/reset-password';
export const Merg_Account_PAGE = '/merge-account';

// Notifications Page
export const NOTIFICATION_PAGE = '/notifications';

// Reservation Page
export const RESERVATION_PAGE = '/reservation';

// Flights Page
export const FLIGHTS_PAGE = '/flights';
export const FLIGHTS_SEARCH_PAGE = '/flights/search';

// Attractions Page
export const ATTRACTIONS_PAGE = '/attractions';
export const ATTRACTIONS_SEARCH_PAGE = '/attractions/search';
export const ATTRACTION_DETAILS_PAGE = '/attraction-details';
export const ATTRACTION_BOOK = '/booking-attraction';
