import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { LOGIN_PAGE, REGISTRATION_PAGE } from 'settings/constant';

const AuthMenu = ({ className }) => {
  const [t] = useTranslation();

  const menuItems = [
    {
      label: <NavLink to={LOGIN_PAGE}>{t('signin')}</NavLink>,
      key: 'menu-1',
    },
    {
      label: <NavLink to={REGISTRATION_PAGE}>{t('signup')}</NavLink>,
      key: 'menu-2',
    },
  ];

  return <Menu className={className} items={menuItems} />;
};

export default AuthMenu;
