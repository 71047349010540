import api from '../API/apiConfig';

export const GetCategories = async () => {
  try {
    const response = await api.post('/categories/Listcategories', {});
    return response.data;
  } catch (error) {
    // console.error('Error fetching categories:', error);
    // Return a mock response in case of an error
    return {
      error: true,
      message: 'Unable to fetch categories. Using mock data.',
      categoriesRecords: [],
    };
  }
};
