import api from '../API/apiConfig';

export const getUser = async (token) => {
  try {
    if (!token) {
      throw new Error('No token provided');
    }

    const response = await api.post(
      '/User/GetUser',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
