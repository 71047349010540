import React, { useState } from 'react';
import { Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LISTING_POSTS_PAGE_SEARCH } from 'settings/constant';

const MapAutoComplete = () => {
  const [searchBox, setSearchBox] = useState();
  let navigate = useNavigate();

  const handleSearch = async (value) => {
    try {
      const response = await axios.post(
        'https://api.quanvex.com/api/tours/Listtours',
        {
          toursRecord: {
            name_en: value,
          },
        }
      );

      navigate(LISTING_POSTS_PAGE_SEARCH, {
        state: {
          searchData: response.data.toursRecords,
        },
      });
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="map_autocomplete">
      <Input
        type="text"
        defaultValue=""
        placeholder="Search By “Program Name”"
        size="large"
        onPressEnter={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};
export default MapAutoComplete;
