import api from '../API/apiConfig';

export const GetCities = async () => {
  try {
    const response = await api.post('/cities/Listcities', {});
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: 'Unable to fetch categories. Using mock data.',
      citiesRecords: [],
    };
  }
};
